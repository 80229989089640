import React from "react";
import Page from "../components/Page";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import StoryblokService from "../utils/storyblok-service";
import { PageContext } from 'src/utils/context'

export default class Index extends React.Component {
  state = {
    story: {
      content: this.props.data.story
        ? JSON.parse(this.props.data.story.content)
        : {},
    },
  };

  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search);
    let {
      data: { story },
    } = await StoryblokService.get(
      `cdn/stories/${this.props.data.story.full_slug}`
    );
    return story;
  }

  async componentDidMount() {
    let story = await this.getInitialStory();
    if (story.content) this.setState({ story });
    setTimeout(() => StoryblokService.initEditor(this), 200);
  }
  

  render() {
    return (
      <PageContext.Provider
        value={{
          hasHero:
            this.state.story.content.body &&
            this.state.story.content.body[0]?.component === "hero" || this.state.story.content.body[0]?.component === "hero_video"
              ? true
              : false,
          currentPage: this.state.story.uuid,
          location: this.props.location,
          component: this.state.story.content.component,
          tag_list: this.state.story.tag_list,
          first_published_at: this.state.story.first_published_at,
          name: this.state.story.name,
          full_slug: this.state.story.full_slug,
          featured_image: this.state.story.content.featured_image
        }}
      >
        <Layout location={this.props.location}>
          <Page blok={this.state.story.content} />
        </Layout>
      </PageContext.Provider>
    );
  }
}

export const query = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "en/" }) {
      name
      content
      full_slug
      uuid
    }
  }
`;
